import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import type { SearchResponse } from "src/api/SearchResponse";
import { getRouteIndexFromHash } from "src/utils/location/routeIndexFromHash";
import {
  type RouteTripCardProps,
  getRoutePropsFromSearchResponse,
} from "../../../components/TripPlanner/TripCard/RouteTripCard/RouteTripCard";
import { getSearchPropsFromSearchResponse } from "../../../components/TripPlanner/TripCard/SearchTripCard/SearchTripCard";
import type { TripCardTypeProps } from "../../../components/TripPlanner/TripCard/TripCard";
import type {
  TripPlannerCardType,
  TripPlannerEntryType,
} from "../TripPlannerProvider";
import type { PartialSearchResponse } from "../util/getPartialSearchResponse";

type AnyTripCardProps = TripCardTypeProps | RouteTripCardProps;

export function createTripCardProperties(
  searchResponse?: SearchResponse | PartialSearchResponse,
  entry?: TripPlannerEntryType,
  defaultToRoute?: boolean
): AnyTripCardProps | undefined {
  const card: TripPlannerEntryType | undefined = entry;

  if (!searchResponse) return undefined;

  const validityProps = getTripCardValidityProps(searchResponse, card);
  const { routeIndex, type } = validityProps;

  const url = card
    ? card.url
    : {
        hash: "",
        pathname: "",
      };

  switch (type) {
    case "search":
      if (defaultToRoute) {
        return getRoutePropsFromSearchResponse(searchResponse, 0, url);
      }
      return getSearchPropsFromSearchResponse(searchResponse, url);
    case "route": {
      return getRoutePropsFromSearchResponse(searchResponse, routeIndex!, url);
    }
    default: {
      return undefined;
    }
  }
}

export function getCardCallback(
  callback: () => void,
  card: AnyTripCardProps,
  index: number
) {
  const { type, gaLabel } = card;

  function sendGAEvent() {
    if (["search", "searchPrompt"].includes(type)) {
      sendAnalyticsInteractionEvent({
        category: "TripPlanner",
        action: "Click:SearchCard",
        label: gaLabel,
      });
    } else {
      sendAnalyticsInteractionEvent({
        category: "TripPlanner",
        action: "Click:TripCard",
        label: gaLabel,
      });
    }
  }

  return function () {
    sendGAEvent();
    callback();
  };
}

type CardTypeValidityProps = {
  type: TripPlannerCardType;
  segmentIndex?: number;
  routeIndex?: number;
};

function getTripCardValidityProps(
  searchResponse: SearchResponse | PartialSearchResponse,
  card?: TripPlannerEntryType
): CardTypeValidityProps {
  if (!card || !card.url?.hash) {
    return {
      type: "search",
    };
  }

  const routeIndex = getCardRouteIndex(card, searchResponse);
  const type = getCardType(card);

  return {
    type,
    routeIndex,
  };
}

export function getCardType(card: TripPlannerEntryType) {
  return card.type === "search" && card.selectedRouteIndex !== undefined
    ? "route"
    : card.type;
}

export function getCardRouteIndex(
  card: TripPlannerEntryType,
  searchResponse?: SearchResponse | PartialSearchResponse
) {
  return card?.selectedRouteIndex !== undefined
    ? card.selectedRouteIndex
    : getRouteIndexFromHash(card?.url.hash, searchResponse);
}
