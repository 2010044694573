import type React from "react";
import type { FeatureConfig } from "src/feature/FeatureConfig";
import Deals from "src/svg/Discount.svg?react";
import Packages from "src/svg/Beach.svg?react";
import Utensils from "src/svg/Utensils.svg?react";
import Activities from "src/svg/Bank.svg?react";
import { useScreenSize } from "src/utils/hooks/useScreenSize";
import styled from "styled-components";
import color from "src/design-system/tokens/color";
import type { Trigger } from "src/analytics/generateExitPoint/generateExitPoint";
import { useLogExitCallback } from "src/analytics/useLogExitCallback/useLogExitCallback";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { useRef } from "react";
import { useAnalyticsEventHasEnteredViewport } from "src/utils/hooks/useAnalyticsEventHasEnteredViewport";
import { getExpediaAttributionLink } from "src/utils/getExpediaAttributionLink";
import { generateNavigationEventId } from "src/analytics/generateNavigationEventId/generateNavigationEventId";
import type { LogExitCategory } from "src/analytics/analyticsEventTypes";
import { PanePromoButton } from "../TripPlanner/PanePromoButton/PanePromoButton";
import { SearchResultsPromo } from "../SearchResultsScreen/SearchResultsPromo/SearchResultsPromo";

type SerpPromoType = Exclude<
  FeatureConfig["AlternativeSerpPromos"],
  "Baseline"
>;

type AlternativeSerpPromoProps = {
  destination: string;
  promoType: SerpPromoType;
  className?: string;
};

export default function AlternativeSerpPromo({
  destination,
  promoType,
  className,
}: AlternativeSerpPromoProps) {
  const isSmallScreenSize = useScreenSize() === "small";
  const message = `${destination} ${promoType}`;
  const promoRef = useRef<HTMLDivElement>(null);
  const { Icon, path, trigger } = promoData[promoType];
  const eventCategory: LogExitCategory = "Promos";

  useAnalyticsEventHasEnteredViewport({
    ref: promoRef,
    category: eventCategory,
    action: `PromoVisible:${promoType}`,
    threshold: 0.5,
  });

  const logExit = useLogExitCallback(eventCategory, "searchResults", trigger);

  const handlePromoClicked = () => {
    const subdomain = isSmallScreenSize ? "mob" : "www";
    const targetUrl = `https://${subdomain}.expedia.com/${path}`;
    const navigationEventId = generateNavigationEventId();

    const expediaLink = getExpediaAttributionLink(
      targetUrl,
      "hotels",
      navigationEventId
    );

    sendAnalyticsInteractionEvent({
      category: eventCategory,
      action: `Click:${promoType}Promo`,
    });
    logExit(targetUrl, "Expedia", undefined, undefined, navigationEventId);

    window.open(expediaLink, "_blank", "noopener");
  };

  return (
    <div ref={promoRef}>
      {isSmallScreenSize ? (
        <SearchResultsPromo
          className={className}
          header={message}
          onClick={handlePromoClicked}
          icon={
            <IconStyles>
              <Icon />
            </IconStyles>
          }
          iconBackgroundColor="mint"
        />
      ) : (
        <PanePromoButton promoText={message} onClick={handlePromoClicked}>
          <Icon />
        </PanePromoButton>
      )}
    </div>
  );
}

const IconStyles = styled.span`
  & path {
    fill: ${color.icon.onBgFill};
  }
`;

const promoData: Record<
  SerpPromoType,
  {
    Icon: () => React.ReactNode;
    path: string;
    trigger: Trigger;
  }
> = {
  Deals: {
    Icon: () => <Deals />,
    path: "deals",
    trigger: "hotelDealsCTA",
  },
  Packages: {
    Icon: () => <Packages />,
    path: "Vacation-Packages",
    trigger: "vacationPackagesCTA",
  },
  Restaurants: {
    Icon: () => <Utensils />,
    path: "lp/t/hotels/Hotels-with-Restaurants",
    trigger: "hotelRestaurantsCTA",
  },
  Activities: {
    Icon: () => <Activities />,
    path: "Activities",
    trigger: "activitiesCTA",
  },
};
