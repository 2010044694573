import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Icon } from "src/components/Icon/Icon";
import { useFeature } from "src/feature/useFeature";
import { ChevronRight } from "src/svg/ChevronRight";
import useSearch from "src/utils/hooks/useSearch";
import { tripHashFromSearchResponse } from "src/utils/location/createTripHashForCard";
import styled from "styled-components";

import color from "src/design-system/tokens/color";
import { spacing } from "src/design-system/tokens/spacing";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import type { Kind } from "../../api/Kind";
import { Skeleton } from "../../components/Skeleton/Skeleton";
import {
  type TypedLocation,
  useTypedLocation,
} from "../../utils/hooks/useTypedLocation";
import { hashAndStateChange } from "../../utils/location/hashAndStateChange";
import { hashChange } from "../../utils/location/hashChange";
import { getSegmentHeadingMessage } from "../SegmentScreen/getSegmentHeadingMessage";
import { messages } from "./NavigationBreadcrumbs.messages";

type NavigationBreadcrumbsProps = {
  routeCanonical?: string;
  segment?: {
    indexInRoute?: number;
    transitKind: Kind;
  };
  showSearchBreadcrumb?: boolean;
  showRouteBreadcrumb?: boolean;
  showTripSummaryBreadcrumb?: boolean;
  showDestinationBreadcrumb?: boolean;
  isTripNavBar?: boolean;
};

type Breadcrumb = {
  text: string;
  linkLocation?: TypedLocation;
};

// Breadcrumbs are shown on Route, Segment and Hotels screens.
export function NavigationBreadcrumbs(props: NavigationBreadcrumbsProps) {
  const intl = useIntl();
  const location = useTypedLocation();
  const { searchResponse } = useSearch();
  const isCoreTransportTab = useFeature("ReturnToCore") && !props.isTripNavBar;

  const destinationBreadcrumb: Breadcrumb = {
    text: intl.formatMessage(messages.destination, {
      destination: searchResponse?.places[1]?.shortName,
    }),
    linkLocation: hashChange(
      tripHashFromSearchResponse(searchResponse, "destination"),
      location
    ),
  };

  const searchResultsBreadcrumb: Breadcrumb = isCoreTransportTab
    ? {
        text: intl.formatMessage(messages.results),
        linkLocation: hashAndStateChange(
          undefined,
          { highlightedTab: "search" },
          location
        ),
      }
    : {
        text: intl.formatMessage(messages.tripPlan),
        linkLocation: hashAndStateChange(
          "#trips",
          { highlightedTab: "search" },
          location
        ),
      };

  const breadcrumbs = [searchResultsBreadcrumb];

  if (props.showSearchBreadcrumb) {
    const isLinked = props.showRouteBreadcrumb || props.segment;
    breadcrumbs.push({
      text: intl.formatMessage(messages.results),
      linkLocation: isLinked
        ? hashChange(tripHashFromSearchResponse(searchResponse), location)
        : undefined,
    });
  }

  if (props.showDestinationBreadcrumb) {
    breadcrumbs.push(destinationBreadcrumb);
  }

  if (props.showRouteBreadcrumb) {
    const tripHash = `${tripHashFromSearchResponse(searchResponse)}/r/${
      props.routeCanonical
    }`;
    const searchHash = `#r/${props.routeCanonical}`;
    const hashLink = isCoreTransportTab ? searchHash : tripHash;
    const routeBreadcrumb: Breadcrumb = {
      text: intl.formatMessage(messages.route),
      // The route doesn't need to be clickable on the route screen because
      // we'd already be on the page we're linking to.
      linkLocation: props.segment ? hashChange(hashLink, location) : undefined,
    };

    // We need to retain the destinationBreadcrumb fromHash when a user navigates between other breadcrumbs.
    // This is only relevant when the routeBreadcrumb is a link (ie. in a segment or schedule screen).
    if (
      props.showDestinationBreadcrumb &&
      destinationBreadcrumb.linkLocation &&
      routeBreadcrumb.linkLocation?.state
    ) {
      routeBreadcrumb.linkLocation.state.fromHash =
        destinationBreadcrumb.linkLocation.hash;
    }

    breadcrumbs.push(routeBreadcrumb);
  }

  if (props.segment) {
    const hash = isCoreTransportTab
      ? `#r/${props.routeCanonical}/s/${props.segment.indexInRoute}`
      : `${tripHashFromSearchResponse(searchResponse)}/r/${
          props.routeCanonical
        }/s/${props.segment.indexInRoute}`;
    const segmentBreadcrumb: Breadcrumb = {
      text: intl.formatMessage(
        getSegmentHeadingMessage(props.segment.transitKind)
      ),
      // Segment breadcrumbs shown on segment screen are not a link, but user can link back from hotels screen.
      linkLocation: props.showTripSummaryBreadcrumb
        ? hashAndStateChange(
            hash,
            { returnsFlowLocation: "departing" },
            location
          )
        : undefined,
    };
    breadcrumbs.push(segmentBreadcrumb);
  }

  if (props.showTripSummaryBreadcrumb) {
    const summaryBreadcrumb: Breadcrumb = {
      text: intl.formatMessage(messages.tripSummary),
      // Trip Summary is the final location of the flow so we never need to link back to it.
      linkLocation: undefined,
    };
    breadcrumbs.push(summaryBreadcrumb);
  }

  return (
    <nav>
      <BreadcrumbList>
        {breadcrumbs.map((breadcrumb, index) => (
          <ListItem key={breadcrumb.text}>
            {index !== 0 && (
              <StyledIcon size="xs">
                <ChevronRight tint="black" />
              </StyledIcon>
            )}
            {breadcrumb.linkLocation ? (
              <BreadcrumbLink
                to={breadcrumb.linkLocation}
                state={{
                  ...breadcrumb.linkLocation.state,
                }}
                onClick={handleBreadcrumbLinkClick(breadcrumb)}
              >
                {breadcrumb.text}
              </BreadcrumbLink>
            ) : (
              <BreadcrumbCurrent>{breadcrumb.text}</BreadcrumbCurrent>
            )}
          </ListItem>
        ))}
      </BreadcrumbList>
    </nav>
  );
}

function handleBreadcrumbLinkClick(breadcrumb: Breadcrumb) {
  return () => {
    if (breadcrumb.text === "Results") {
      sendAnalyticsInteractionEvent({
        category: "PaneNav",
        action: "Click:SearchResultsBreadcrumb",
      });
    } else if (breadcrumb.text === "Trip Summary") {
      sendAnalyticsInteractionEvent({
        category: "PaneNav",
        action: "Click:RouteBreadcrumb",
      });
    }
  };
}

export function LoadingBreadcrumbs(props: { className?: string }) {
  return (
    <nav className={props.className}>
      <BreadcrumbList>
        <ListItem key={0}>
          <Skeleton variant="body" width="52px" margin="0 auto" inline />
        </ListItem>
        <ListItem key={1}>
          <Skeleton variant="body" width="120px" margin="0 auto" inline />
        </ListItem>
        <ListItem key={2}>
          <Skeleton variant="body" width="52px" margin="0 auto" inline />
        </ListItem>
      </BreadcrumbList>
    </nav>
  );
}

const BreadcrumbList = styled.ol`
  display: inline-flex;
  align-items: center;
  gap: ${spacing.xl};
  flex-wrap: wrap;
`;

const ListItem = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const StyledIcon = styled(Icon)`
  margin-right: ${spacing.xl};
`;

const BreadcrumbLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: ${color.text.text};

  &:hover {
    text-decoration: underline;
  }
`;

const BreadcrumbCurrent = styled.span`
  // add opacity to the color to make it lighter as theme.breadcrumbs
  // is being overwritten elsewhere in code, so I can't add a new property to it
  // TODO: fix this (CONV-1371)
  color: ${color.text.primary.primary};
`;
