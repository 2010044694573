import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { useTransportDataPartners } from "src/data-partners/useDataPartner";
import color from "src/design-system/tokens/color";
import { spacing } from "src/design-system/tokens/spacing";
import { useIsHotelsUrlDeeplink } from "src/utils/hooks/useNavigateToHotelsPage";
import { useScreenMinWidth } from "src/utils/hooks/useScreenMinWidth";
import { navigateToNewStateHash } from "src/utils/location/navigateToNewStateHash";
import { getUidCookie } from "src/utils/uid";
import styled, { css } from "styled-components";
import type { GeocodedPlace } from "src/PrefetchData";
import useUser from "src/utils/hooks/useUser";
import { useFeature } from "src/feature/useFeature";
import { searchResultScreenComponentList } from "../../analytics/generateScreenComponentsRendered/searchResultScreenComponentList";
import { DefaultErrorBoundary } from "../../components/DefaultErrorBoundary/DefaultErrorBoundary";
import { color as colorTheme, screenMinWidth } from "../../theme";
import { useAnalyticsPageView } from "../../utils/hooks/useAnalyticsPageView";
import useSearch from "../../utils/hooks/useSearch";
import { useTypedLocation } from "../../utils/hooks/useTypedLocation";
import { useTripPlannerContext } from "../TripPlanner/hooks/useTripPlannerContext";
import { updateTripPlanningState } from "../TripPlanner/hooks/useTripPlanningState";
import { TripNavbar } from "../TripPlanner/TripNavbar/TripNavbar";
import { HotelsPromo } from "../TripPlanner/HotelsPromo/HotelsPromo";
import CarRentalPromo from "../TripPlanner/CarRentalPromo/CarRentalPromo";
import { useHotelsContext } from "../HotelsScreen/HotelsContext";
import AlternativeSerpPromo from "../AlternativeSerpPromo/AlternativeSerpPromo";
import { SearchBar } from "./SearchBar/SearchBar";
import { UkraineSearchNotification } from "./SearchNotification/UkraineSearchNotification";
import {
  type Errors,
  SearchResultError,
} from "./SearchResultError/SearchResultError";
import { SearchResultsCarHirePromo } from "./SearchResultsCarHirePromo/SearchResultsCarHirePromo";
import { SearchResultsContent } from "./SearchResultsContent/SearchResultsContent";
import { SearchResultsHotelPromo } from "./SearchResultsHotelPromo/SearchResultsHotelPromo";
import { SearchResultsLoading } from "./SearchResultsLoading/SearchResultsLoading";
import messages from "./SearchResultsScreen.messages";
import { useEmulateReload } from "./useEmulateReload";

type Props = {
  context: "transport" | "tripPlanner";
};

export function SearchResultsScreen(props: Props) {
  const location = useTypedLocation();
  const navigate = useNavigate();
  const { isMobile } = useScreenMinWidth();
  const intl = useIntl();
  const uid = getUidCookie();
  const isHotelsDeeplink = useIsHotelsUrlDeeplink();
  const { searchResponse, destination } = useSearch();
  const { emulateReload } = useEmulateReload();
  const { destination: hotelsDestination } = useHotelsContext();
  const isEnglish = useUser().language === "en";
  const alternativeSerpPromo = useFeature("AlternativeSerpPromos");

  const components = searchResultScreenComponentList(searchResponse);

  useTransportDataPartners(
    searchResponse,
    searchResponse?.analytics?.interest_data,
    uid
  );

  useAnalyticsPageView(
    {
      pagePath: location.pathname + location.search + location.hash,
      pageLocation: window.location.href,
    },
    searchResponse,
    "SearchResults",
    undefined,
    components
  );

  const { tripPlannerDetails, dispatch, tripPlanningState } =
    useTripPlannerContext();

  function handleTripSave(routeIndex?: string) {
    if (searchResponse) {
      dispatch({
        type: "SAVE_SEARCH",
        searchResponse,
        url: {
          pathname: location.pathname,
          hash: `#trips/r/${
            searchResponse.routes[Number(routeIndex)].canonicalName
          }`,
        },
        routeIndex: routeIndex ? Number(routeIndex) : undefined,
      });

      updateTripPlanningState(
        tripPlanningState,
        tripPlannerDetails,
        searchResponse,
        undefined,
        undefined,
        "Search"
      );
    }
  }

  function navigateToTripsScreen() {
    navigateToNewStateHash(navigate, {}, "#trips", {
      ...location,
    });
  }

  return (
    <Wrapper>
      {props.context === "tripPlanner" && (
        <TripNavbar
          variant={isMobile ? "default" : "fullScreen"}
          showSearchBreadcrumb
          onBackClick={isMobile ? undefined : navigateToTripsScreen}
          headingText={intl.formatMessage(messages.travelOptions)}
        />
      )}
      {props.context === "transport" && isMobile && (
        <SearchBarWrapper>
          <SearchBar />
        </SearchBarWrapper>
      )}
      <Content $isTripsDrawer={props.context === "tripPlanner"}>
        {components.includes("SearchNotification") && (
          <UkraineSearchNotification />
        )}
        {components.includes("RouteList") && (
          <DefaultErrorBoundary defaultMessage>
            <RouteList
              context={props.context}
              isLoading={emulateReload}
              handleTripSave={handleTripSave}
              isHotelsScreen={isHotelsDeeplink}
            />
          </DefaultErrorBoundary>
        )}
        {!isMobile && props.context === "transport" && (
          <PromoButtons>
            {components.includes("HotelCta") && hotelsDestination && (
              <HotelsPromo location={hotelsDestination as GeocodedPlace} />
            )}
            {components.includes("RentalCarCta") && <CarRentalPromo />}
          </PromoButtons>
        )}
        {isMobile && props.context === "transport" && (
          <>
            {components.includes("HotelCta") && (
              <StyledSearchResultsHotelPromo />
            )}
            {components.includes("RentalCarCta") && (
              <DefaultErrorBoundary>
                <StyledSearchResultsCarHirePromo />
              </DefaultErrorBoundary>
            )}
            {alternativeSerpPromo !== "Baseline" &&
              isEnglish &&
              destination &&
              components.includes("AlternativeSerpPromo") && (
                <StyledAlternativeSerpPromo
                  promoType={alternativeSerpPromo}
                  destination={destination.shortName}
                />
              )}
          </>
        )}
      </Content>
    </Wrapper>
  );
}

const SearchResultPromoStyles = css`
  border-bottom: 1px solid ${color.border.secondary};
  ${screenMinWidth.sm} {
    border-bottom: 1px solid ${colorTheme.grey2};
  }
`;

const StyledSearchResultsCarHirePromo = styled(SearchResultsCarHirePromo)`
  ${SearchResultPromoStyles}
`;

const StyledSearchResultsHotelPromo = styled(SearchResultsHotelPromo)`
  ${SearchResultPromoStyles}
`;

const StyledAlternativeSerpPromo = styled(AlternativeSerpPromo)`
  ${SearchResultPromoStyles}
`;

function RouteList(props: {
  context: "transport" | "tripPlanner";
  isLoading: boolean;
  handleTripSave: (routeIndex?: string) => void;
  isHotelsScreen?: boolean;
}) {
  const { origin, destination, searchResponse, isLoading, error } = useSearch();
  const searchResponsePending = props.isLoading || isLoading;
  const hasResults = !!searchResponse?.routes?.length;

  if (error) {
    return <ErrorContent errorType="error" />;
  } else if (searchResponse && !hasResults) {
    return <ErrorContent errorType="no-results" />;
  } else if (!origin) {
    return <ErrorContent errorType="no-origin" />;
  } else if (!destination) {
    return <ErrorContent errorType="no-destination" />;
  } else if (searchResponsePending) {
    return <SearchResultsLoading />;
  } else if (searchResponse) {
    return (
      <SearchResultsContent
        context={props.context}
        searchResponse={searchResponse}
        handleTripSave={props.handleTripSave}
      />
    );
  } else {
    return null;
  }
}

function ErrorContent(props: { errorType: Errors }) {
  return (
    <ErrorComponent>
      <SearchResultError errorType={props.errorType} />
    </ErrorComponent>
  );
}

const Wrapper = styled.div`
  position: relative;
  display: inline;
`;

const ErrorComponent = styled.div`
  padding: ${spacing.xl};
`;

const Content = styled.div<{ $isTripsDrawer?: boolean }>`
  ${({ $isTripsDrawer }) =>
    !$isTripsDrawer &&
    css`
      background-color: ${color.bg.fill.hover};
      padding: ${spacing.md} ${spacing.md} ${spacing.xxl} ${spacing.md};
      border-bottom: 1px solid ${color.border.secondary};

      ${screenMinWidth.sm} {
        padding: ${spacing.xxl} ${spacing.xl} ${spacing.xl};
        background-color: ${color.bg.fill.active};
      }
    `}
`;

export const SearchBarWrapper = styled.div`
  position: sticky;
  left: 0;
  top: 0;
  right: 0;
  z-index: 50;
`;

const PromoButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.lg};
  padding-top: ${spacing.md};
`;
