import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import type { HotelProviderKind } from "src/utils/types/accommodationProviders";
import {
  type Trigger,
  generateExitPoint,
} from "src/analytics/generateExitPoint/generateExitPoint";
import { logDataPartnerHotelExit } from "src/data-partners/logDataPartnerExit";
import type { AnalyticsEventCategory } from "src/analytics/analyticsEventTypes";
import type { SupportedLanguageCode } from "src/utils/language";
import type { SearchResponse } from "src/api/SearchResponse";
import { getHotelRedirectDeeplinkUrl } from "src/utils/url";
import { useIntl } from "react-intl";
import { localeToLanguageCode } from "src/utils/conversions/languageCode";
import useSearch from "src/utils/hooks/useSearch";
import { useLayout } from "src/utils/hooks/useLayout";
import { dateToFormattedISOString, formatRoomDetails } from "./url";
import type { DateCombos, RoomDetail } from "./HotelsContext";

type ExitLocations =
  | "listCard"
  | "mapCard"
  | "tripCard"
  | "genericProviderExit" //i.e to a general page rather than a specific hotel.
  | "hexEntry"; // 20240916 for experiment to replace HEx with Kayak exit.

type Props = {
  variant: ExitLocations;
  actionType?: "Click" | "Enter" | "Tap";
  hotelUrl?: string;
  logExitCallback?: (
    destinationUrl: string,
    provider?: string,
    agencyIds?: string[],
    overrideTrigger?: Trigger
  ) => void;
  provider?: HotelProviderKind;
  cardIndex?: number;
  deal?: boolean;
  freeCancellation?: boolean;
  destinationName?: string;
  hotelName?: string;
  hotelStarRating?: number;
  stayDates?: DateCombos;
  languageCode?: SupportedLanguageCode;
  searchResponse?: SearchResponse;
  avgDailyRate?: number;
  destinationState?: string;
  roomDetails?: RoomDetail[];
  isCrimtanEnabled?: boolean;
  isAdaraEnabled?: boolean;
};

// The goal here is to be a central utility for accommodation provider exits.
// This is important as we introduce 3rd party data activities on these exits, such as crimtan.
// We don't want to maintain that kind of thing across multiple locations.

// Exit locations unaccounted for:
// AccommodationPromoCell (currently unused 20240625) TODO: can delete?
export function exitToProviderWithLogging({
  variant,
  hotelUrl,
  actionType = "Click",
  logExitCallback,
  provider,
  cardIndex,
  deal = false,
  freeCancellation = false,
  destinationName,
  destinationState,
  hotelName,
  hotelStarRating,
  stayDates,
  languageCode,
  searchResponse,
  avgDailyRate,
  roomDetails,
  isCrimtanEnabled = false,
  isAdaraEnabled = false,
}: Props) {
  const capitalizedActionType =
    actionType.charAt(0).toUpperCase() + actionType.slice(1);
  let category: AnalyticsEventCategory = "Hotels",
    action = `${capitalizedActionType}:HotelProviderExit`,
    label,
    formattedHotelUrl;

  const dealLabel = deal ? "-deal" : "";
  const freeCancelLabel = freeCancellation ? "-freeCancel" : "";

  if (hotelUrl && provider && hotelUrl) {
    switch (variant) {
      case "listCard":
        label = `HotelListCard:card_${cardIndex}${dealLabel}`;
        formattedHotelUrl = getLabelledHotelListCardUrl(hotelUrl);
        break;
      case "mapCard":
        label = `HotelPinCard:card${freeCancelLabel}${dealLabel}`;
        formattedHotelUrl = getLabelledHotelListCardUrl(hotelUrl);
        break;
      case "tripCard":
        category = "TripHotel";
        label = `HotelListCard`;
        formattedHotelUrl = getLabelledHotelListCardUrl(hotelUrl);
        break;
      case "genericProviderExit":
        action = `${actionType}:MoreAccommodationButton`;
        label = "";
        formattedHotelUrl = hotelUrl;
        break;
      case "hexEntry":
        category = "Hotels";
        label = `hexEntry`;
        formattedHotelUrl = hotelUrl;
    }

    sendAnalyticsInteractionEvent({
      category: category,
      action: action,
      label: label,
    });

    if ((isCrimtanEnabled || isAdaraEnabled) && destinationName && hotelName) {
      logDataPartnerHotelExit({
        destination: destinationName,
        destinationState,
        hotelName,
        hotelStarRating,
        checkinDate: stayDates?.[0],
        checkoutDate: stayDates?.[1],
        languageCode,
        searchResponse,
        avgDailyRate,
        roomDetails,
        isCrimtanEnabled,
        isAdaraEnabled,
      });
    }

    if (logExitCallback) {
      logExitCallback(formattedHotelUrl, provider);
    }

    window.open(formattedHotelUrl, "_blank", "noopener");
  }
}

const labelHotelListCardExitPoint = generateExitPoint("hotels", "hotelCard");
function getLabelledHotelListCardUrl(hotelUrl: string) {
  return `${hotelUrl}&label=${labelHotelListCardExitPoint}`;
} // The url does not contain tracking information, so we have to add it.

export function useExitOnHExEntry(provider: HotelProviderKind) {
  const DEFAULT_MOBILE_CHECKIN = 7;
  const DEFAULT_DESKTOP_CHECKIN = 30;
  const DEFAULT_STAY_LENGTH = 1;
  const DEFAULT_GUESTS = [{ adults: 2, children: [] }];

  const isMobile = useLayout() === "mobile";
  const intl = useIntl();
  const { searchResponse, destination } = useSearch();

  // Experimenting with Expedia only. 202412.
  if (provider !== "Expedia") return;

  const language = localeToLanguageCode(intl.locale);
  const exitPoint = generateExitPoint("hotels", "hotelSearch");

  const checkIn = new Date();
  checkIn.setDate(
    checkIn.getDate() +
      (isMobile ? DEFAULT_MOBILE_CHECKIN : DEFAULT_DESKTOP_CHECKIN)
  );
  const checkOut = new Date(checkIn);
  checkOut.setDate(checkOut.getDate() + DEFAULT_STAY_LENGTH);

  function exitToHotel({
    lat,
    lng,
    canonicalName,
  }: {
    lat?: number;
    lng?: number;
    canonicalName?: string;
  }) {
    const locationString = lat && lng ? `(${lat},${lng})` : canonicalName;

    const destinationUrl = getHotelRedirectDeeplinkUrl(
      language,
      exitPoint,
      searchResponse?.request.requestId,
      locationString,
      dateToFormattedISOString(checkIn),
      dateToFormattedISOString(checkOut),
      provider,
      formatRoomDetails(DEFAULT_GUESTS)
    );

    // Perform the exit.
    exitToProviderWithLogging({
      variant: "hexEntry",
      actionType: "Click",
      hotelUrl: destinationUrl,
      logExitCallback: undefined,
      provider,
      destinationName: canonicalName ?? destination?.canonicalName,
      stayDates: [checkIn, checkOut],
      languageCode: language,
    });
  }

  return exitToHotel;
}
