import {
  snowplowEntities,
  type SnowplowEntity,
} from "src/analytics/snowplowEntities";
import logBreadcrumbs from "src/utils/logBreadcrumbs";
import type { StructEventParams } from "src/utils/types/snowplow";
import { UNSAFE_featureConfigEscapeHatch } from "../feature/UNSAFE_useConfigureFeatureEscapeHatch";
import { updateSessionInfo } from "./useAnalyticsCoreExperience/session";

type InteractionContext = ReturnType<
  typeof snowplowEntities.interaction.create
>;
type Contexts = [InteractionContext, ...SnowplowEntity[]];

export function sendAnalyticsNonInteractionEvent({
  category,
  action,
  label,
  value,
  extraInfo,
}: StructEventParams) {
  const contexts: Contexts = [snowplowEntities.interaction.create(false)];
  if (extraInfo) {
    contexts.push(snowplowEntities.extraInfo.create(extraInfo));
  }
  sendAnalyticsEvent({ category, action, label, value, contexts });
}

export function sendAnalyticsInteractionEvent({
  category,
  action,
  label,
  value,
  extraInfo,
}: StructEventParams) {
  updateSessionInfo();

  const contexts: Contexts = [snowplowEntities.interaction.create(true)];
  if (extraInfo) {
    contexts.push(snowplowEntities.extraInfo.create(extraInfo));
  }
  sendAnalyticsEvent({ category, action, label, value, contexts });
}

/** @deprecated sendAnalyticsEvent is exported for now, but it's only intended to be used by
 *  other sendAnalyticsXEvent functions, not directly by the app.
 */
export function sendAnalyticsEvent({
  category,
  action,
  label,
  value,
  contexts,
}: Omit<StructEventParams, "contexts"> & { contexts: Contexts }) {
  const sampleRate = Math.min(
    parseSampleRate(category),
    parseSampleRate(action)
  );
  const isSelectedSample = Math.random() <= sampleRate;

  const isUsingSnowplowAnalytics = isSelectedSample && window.snowplow;

  const isInteraction = contexts[0].data.interaction;

  if (UNSAFE_featureConfigEscapeHatch.LogAnalyticsEventsToConsole) {
    logToConsole(category, action, label, value, isInteraction, ...contexts);
  }

  if (isInteraction) {
    logBreadcrumbs(category, action, label, value);
  }

  if (isUsingSnowplowAnalytics) {
    window.snowplow("trackStructEvent", {
      category,
      action,
      label,
      property: undefined,
      value: value?.toString(),
      context: contexts,
    });
  }
}

function parseSampleRate(value: string): number {
  const isSampled = value.match(/\[sample=(0\.\d+)\]/);
  return isSampled ? parseFloat(isSampled[1]) : 1;
}

function logToConsole(
  category: string,
  action: string,
  label?: string,
  value?: number,
  isInteraction?: boolean,
  ...contexts: SnowplowEntity[]
) {
  const stack = new Error().stack;
  let callLine: string[] = [];
  if (stack) {
    const callStack = stack.split("\n")[3];
    const callFile = callStack.split("/").pop();
    if (callFile) {
      callLine = callFile.split(":");
    }
  }

  console.log(
    `» Sp %c${category} %c${!isInteraction ? "ni" : ""}%c${action ?? ""} %c${
      label ?? ""
    } %c${value ?? ""} %c[${callLine[0] + ":" + callLine[1]}]`,
    "color:#276bb0;",
    "color:grey;border:solid 1px grey;font-size:9px;border-radius:2px;margin-right:6px;",
    "color:#2e8b57;",
    "color:#9c27b0;",
    "color:#999;",
    "color:#aaa;font-size:90%",
    ...contexts
  );
}
