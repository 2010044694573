// This function facilitates attribution for Expedia links and is based
// on the requisite core functionality, see:
// https://github.com/rome2rio/rome2rio-core/blob/e6f36a4f186496eaca67de983ccb4117df365762/rome2rio/Code/Store/Hotels/Providers/ExpediaProvider.cs#L574

export function getExpediaAttributionLink(
  targetUrl: string,
  adref: "popunder" | "hotels",
  navigationEventId: string
) {
  // The tracking code currently assumes US Expedia (eg: expedia.com)
  // A full list of tracking codes can be seen here:
  // https://github.com/rome2rio/rome2rio-core/blob/e6f36a4f186496eaca67de983ccb4117df365762/rome2rio/Code/Store/Hotels/Providers/ExpediaProvider.cs#L107
  const trackingCode = "1101l3g2";

  const urlPrefix = `https://prf.hn/click/camref:${trackingCode}/pubref:${navigationEventId}/adref:${adref}/destination:`;

  return urlPrefix + targetUrl;
}
