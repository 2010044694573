import { isDebugBySearchParams } from "src/utils/isDebugBySearchParams";
import { isContentTest, isPreview } from "../utils/host";

export const PossibleFeaturesMap = {
  Debug: [false, true],
  LogAnalyticsEventsToConsole: [false, true],
  FillAds: ["default", "never", "alwaysWithTestAds"],

  // Don't delete, required for content-test
  SkipRoutes: [false, true],
  UseStaticConnectedSchedules: [
    "never",
    "FullyScheduleableNonBookableRoutes",
    "FullyBookableRoutesFallback",
    "FirstOrLastWalkingSegmentRoutes",
  ],
  SkipNonBookableRoutes: [false, true],
  ForceUseLiveScheduleFallback: [false, true],
  UseDarkMode: [false, true],
  GoogleCustomButton: [false, true],
  KayakInlineAds: [false, true],
  SchedulesFeedbackButton: [false, true],
  DelayPrebidUntilConsentReady: [false, true],
  CmpDefaultConsent: [false, true],
  StackedNavigationAccom: [false, true],
  HExScrollNudge: [false, true],
  PreDestinationPages: ["baseline", "card", "transitInfo"],
  HExRefineSearchKind: ["baseline", "suburb", "attraction"],
  HExRefineSearchPosition: ["baseline", "top", "mid"],
  ReactQueryDevTools: [false, true],
  FreelancerQA: ["default", "login", "hide"],
  DataOpsQA: [false, true],
  HExCTACopyPortuguese: [
    "Baseline",
    "ViewOffer",
    "CheckOutTheHotel",
    "SeeTheHotel",
    "SeeMore",
    "SeeDetails",
    "ViewOptions",
  ],
  HExCTACopyFrench: [
    "Baseline",
    "MoreInfo",
    "DisplayDeal",
    "SeeHotel",
    "SeeStay",
    "ViewNow",
    "ViewTheOptions",
  ],
  HExCTACopySpanish: [
    "Baseline",
    "ViewDeal",
    "FindDeal",
    "SeeHotel",
    "SeeDetails",
    "ViewNow",
  ],
  HExCTACopyGerman: ["Baseline", "FindDeal", "ViewMore", "SeeDetails", "View"],
  IslandRoutingQA: [false, true],
  MobileTripsV2QA: [false, true],
  ExpediaOnEntry: [false, true], // EPs to HEx instead popout to Expedia.
  ReturnToCore: [false, true],
  SearchExperienceQA: [false, true],
  InterstitialAd: [false, true],
  AlternativeSerpPromos: [
    "Baseline",
    "Packages",
    "Deals",
    "Activities",
    "Restaurants",
  ],
  SchedulesBest: [false, true],
  RightRailBP: [false, true],
  SERPInlineAdFirstResult: [false, true],
  AlternativeSerpPromoQA: [false, true],
  SERPNewTabsQA: [false, true],
  NewTabSERPExperience: [false, true],
} as const;

// Note: We do not include the 'test' environment as isDevelopment as all features here are presentational only
const isDevelopment = import.meta.env.MODE === "development";

export const defaultFeatureConfig: FeatureConfig = {
  Debug: isDevelopment || isPreview(),
  LogAnalyticsEventsToConsole:
    isDevelopment || isPreview() || isDebugBySearchParams(),
  FillAds: isDevelopment || isPreview() ? "alwaysWithTestAds" : "default",
  SkipRoutes: !isContentTest(),
  UseStaticConnectedSchedules: "FirstOrLastWalkingSegmentRoutes",
  SkipNonBookableRoutes: true,
  ForceUseLiveScheduleFallback: false,
  UseDarkMode: false,
  // For further information on this feature, see: https://rome2rio.atlassian.net/wiki/spaces/~630c49a1d8850343ef57e7e9/pages/2709291041/Google+Sign+In+for+Explore+GAPI+vs+GSI
  GoogleCustomButton: false,
  KayakInlineAds: true,
  SchedulesFeedbackButton: false,
  DelayPrebidUntilConsentReady: !isDevelopment && !isPreview(),
  CmpDefaultConsent: isDevelopment || isPreview(),
  StackedNavigationAccom: false,
  HExScrollNudge: false,
  PreDestinationPages: "baseline",
  HExRefineSearchKind: "baseline",
  HExRefineSearchPosition: "baseline",
  ReactQueryDevTools: false,
  FreelancerQA: "default",
  DataOpsQA: false,
  HExCTACopyPortuguese: "Baseline",
  HExCTACopyFrench: "Baseline",
  HExCTACopySpanish: "Baseline",
  HExCTACopyGerman: "Baseline",
  IslandRoutingQA: false,
  MobileTripsV2QA: false,
  ExpediaOnEntry: false,
  ReturnToCore: false,
  SearchExperienceQA: false,
  InterstitialAd: false,
  AlternativeSerpPromos: "Baseline",
  SchedulesBest: false,
  RightRailBP: false,
  SERPInlineAdFirstResult: false,
  AlternativeSerpPromoQA: false,
  SERPNewTabsQA: false,
  NewTabSERPExperience: false,
};

export type FeatureName = keyof typeof PossibleFeaturesMap;

export type FeatureValue<F extends FeatureName> =
  (typeof PossibleFeaturesMap)[F][number];

export type FeatureConfig = {
  [F in FeatureName]: FeatureValue<F>;
};
