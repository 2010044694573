import {
  type CollisionDetection,
  type DragEndEvent,
  type DragOverEvent,
  type DragStartEvent,
  DndContext,
  MouseSensor as LibMouseSensor,
  TouchSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, arrayMove, useSortable } from "@dnd-kit/sortable";
import {
  type MouseEvent,
  type PropsWithChildren,
  type TouchEvent,
  useState,
} from "react";
import type { SortableObject } from "src/components/DragAndDropList/DraggableItem";
import { border_radius } from "src/design-system/tokens/border";
import { A11yOutline } from "src/utils/accessibility";
import styled from "styled-components";
import { useTripPlannerContext } from "../hooks/useTripPlannerContext";

type DragContextWrapperProps<T> = PropsWithChildren<{
  onDragStart?: (event: DragStartEvent) => void;
  onDragEnd?: (event: DragEndEvent, hasSwapped: boolean) => void;
  onDragOver?: (event: DragOverEvent) => void;
  sortableItems: SortableObject<T>[];
  setSortableItems: React.Dispatch<React.SetStateAction<SortableObject<T>[]>>;
  collisionDetection?: CollisionDetection;
}>;

export default function DragContextWrapper<T>({
  children,
  onDragStart,
  onDragEnd,
  onDragOver,
  sortableItems,
  setSortableItems,
  collisionDetection = pointerWithin,
}: DragContextWrapperProps<T>) {
  // Block DnD event propagation if element have "data-no-dnd" attribute
  // https://github.com/clauderic/dnd-kit/issues/477
  const handler = ({ nativeEvent: event }: MouseEvent | TouchEvent) => {
    let cur = event.target as HTMLElement;

    while (cur) {
      if (cur.dataset?.noDnd) {
        return false;
      }
      cur = cur.parentElement as HTMLElement;
    }

    return true;
  };

  class MouseSensor extends LibMouseSensor {
    static override readonly activators = [
      { eventName: "onMouseDown", handler },
    ] as (typeof LibMouseSensor)["activators"];
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Enable drag function when dragging more than 2px as to not block click events
      activationConstraint: {
        distance: 2,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 2,
      },
    })
  );

  function handleDragStart(event: DragStartEvent) {
    onDragStart?.(event);
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    const isDifferentOrder = !!over && active.id !== over.id;
    if (isDifferentOrder) {
      const activeIndex = sortableItems.findIndex(
        (items) => items.id === active.id
      );
      const overIndex = sortableItems.findIndex(
        (items) => items.id === over.id
      );
      const newOrder = arrayMove(sortableItems, activeIndex, overIndex);
      setSortableItems(newOrder);
    }
    onDragEnd?.(event, isDifferentOrder);
  }

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragOver={onDragOver}
      collisionDetection={collisionDetection}
      sensors={sensors}
    >
      <SortableContext items={sortableItems.map((item) => item.id)}>
        {children}
      </SortableContext>
    </DndContext>
  );
}

type UseDragContextHandlersProps<T> = {
  sortableItems: SortableObject<T>[];
  setSortableItems: React.Dispatch<React.SetStateAction<SortableObject<T>[]>>;
  onDragStart?: (event: DragStartEvent) => void;
  onDragOver?: (event: DragOverEvent) => void;
  onDragEnd?: (event: DragEndEvent, hasSwapped: boolean) => void;
};
export function useDragContextHandlers<T>(
  props: UseDragContextHandlersProps<T>
) {
  const [activeDragItem, setActiveDragItem] = useState<
    SortableObject<T> | undefined
  >();

  function onDragStart(event: DragStartEvent) {
    setActiveDragItem(
      props.sortableItems.find((item) => item.id === event.active.id)
    );
    props.onDragStart?.(event);
  }

  function onDragOver(event: DragOverEvent) {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      props.setSortableItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
    props.onDragOver?.(event);
  }

  function onDragEnd(event: DragEndEvent, hasSwapped: boolean) {
    setActiveDragItem(undefined);
    props.onDragEnd?.(event, hasSwapped);
  }

  return { activeDragItem, onDragStart, onDragOver, onDragEnd };
}

type SortableWrapperProps = PropsWithChildren<{
  id: string;
  sortableGroupID?: string;
}>;
export function SortableWrapper({
  children,
  id,
  sortableGroupID,
}: SortableWrapperProps) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id,
    });

  // We want to disable dragging when just a -> b pair
  const { isMultiTrip } = useTripPlannerContext();
  const listenersOnState = isMultiTrip ? { ...listeners } : undefined;

  const style = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : undefined,
    transition,
  };

  return (
    <SortableWrapperLi
      className={sortableGroupID}
      ref={setNodeRef}
      style={style}
      id={id}
      {...attributes}
      {...listenersOnState}
      tabIndex={undefined}
    >
      {children}
    </SortableWrapperLi>
  );
}

const SortableWrapperLi = styled.li`
  list-style-type: none;
  border-radius: ${border_radius.rounded_sm};
  ${A11yOutline};
`;
