import { useState } from "react";
import { useIntl } from "react-intl";
import type { GeocodedPlace } from "src/PrefetchData";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { CloseButton } from "src/auth/components/CloseButton/CloseButton";
import { Button } from "src/components/Button/Button";
import { Dialog } from "src/components/Dialog/Dialog";
import type { SortableObject } from "src/components/DragAndDropList/DraggableItem";
import { Icon } from "src/components/Icon/Icon";
import { Info } from "src/svg/Info";
import { color, fontSize, spacing } from "src/theme";
import { A11yOutline } from "src/utils/accessibility";
import useUser from "src/utils/hooks/useUser";
import { useCreateTripFromSearch } from "src/utils/useCreateTripFromSearch";
import styled from "styled-components";
import { useTripPlannerContext } from "../hooks/useTripPlannerContext";
import { FocusTrap } from "../../../components/FocusTrap/FocusTrap";
import { LazyMultiSearchInput } from "./LazyMultiSearchInput";
import messages from "./MultiSearchModal.messages";

type Props = {
  setShowMultiSearchModal: (show: boolean) => void;
};

export function MultiSearchModal({ setShowMultiSearchModal }: Props) {
  const { tripPlannerDetails } = useTripPlannerContext();
  const [destinations, setDestinations] = useState<
    SortableObject<GeocodedPlace>[]
  >([]);
  const intl = useIntl();
  const { user } = useUser();
  const isLoggedIn = !!user?.id;

  const {
    addOrChangePlaceInPosition,
    createTrip,
    removePlace,
    overridePlaces,
  } = useCreateTripFromSearch({
    origin: destinations[0],
    destination: destinations[1],
    setOrigin: () => {},
    setDestination: () => {},
  });

  function handleCancel() {
    setShowMultiSearchModal(false);
    clearAll();
    sendAnalyticsInteractionEvent({
      category: "TripPlanner",
      action: "Click:CloseSearch:MultipleTrips",
    });
  }

  function handleSameSearch() {
    setShowMultiSearchModal(false);
    clearAll();
    sendAnalyticsInteractionEvent({
      category: "TripPlanner",
      action: "Click:Search:MultipleTrips",
    });
  }

  function handleCreateTrip() {
    createTrip();
    setShowMultiSearchModal(false);
    sendAnalyticsInteractionEvent({
      category: "TripPlanner",
      action: "Click:Search:MultipleTrips",
    });
    clearAll();
  }

  function clearAll() {
    setDestinations([]);
    overridePlaces([]);
  }
  function handleClearAllClick() {
    clearAll();
    sendAnalyticsInteractionEvent({
      category: "TripPlanner",
      action: "Click:ClearAll:MultipleTrips",
    });
  }

  const arePlacesTheSameAsOriginal =
    destinations.length === tripPlannerDetails.places.length &&
    !destinations.find(
      (destination, index) =>
        destination.canonicalName !==
        tripPlannerDetails.places[index].canonicalName
    );

  return (
    <>
      <StyledDialog
        isOpen
        onBackdropClicked={handleCancel}
        onClickOutside={handleCancel}
      >
        <FocusTrap isActive>
          <Header>
            <h3>{intl.formatMessage(messages.multiSearchWhereToGo)}</h3>
            <CloseButton onClick={() => handleCancel()} />
          </Header>
          <LazyMultiSearchInput
            destinations={destinations}
            setDestinations={setDestinations}
            addOrChangePlaceInPosition={addOrChangePlaceInPosition}
            overridePlaces={overridePlaces}
            removePlace={removePlace}
            showMultiSearchModal
          />
          <FooterWrapper>
            {!isLoggedIn && (
              <MessageWrapper>
                <Icon size="md">
                  <Info tint="cod" />
                </Icon>
                <SaveMessage>
                  {intl.formatMessage(messages.multiSearchSaveWarning)}
                </SaveMessage>
              </MessageWrapper>
            )}
            <ButtonWrapper>
              <ClearButton
                backgroundColor="transparent"
                textColor="primaryOnLight"
                size="medium"
                onClick={handleClearAllClick}
                disabled={!destinations.length}
                inline
              >
                {intl.formatMessage(messages.clear)}
              </ClearButton>
              <CTAButton
                data-testid="create-trip-modal-button"
                backgroundColor="pink"
                textColor="primaryOnDark"
                size="medium"
                disabled={destinations.length <= 1}
                onClick={
                  arePlacesTheSameAsOriginal
                    ? handleSameSearch
                    : handleCreateTrip
                }
                inline
              >
                {intl.formatMessage(messages.multiSearch)}
              </CTAButton>
            </ButtonWrapper>
          </FooterWrapper>
        </FocusTrap>
      </StyledDialog>
    </>
  );
}

const StyledDialog = styled(Dialog)`
  padding: ${spacing.xxl};
  max-width: 672px; // override dialog
  width: 672px;
  overflow: visible;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SaveMessage = styled.span`
  font-size: ${fontSize.md};
  margin-left: ${spacing.lg};
`;

const CTAButton = styled(Button)`
  ${A11yOutline};
  &:focus-visible {
    box-shadow: inset 0px 0px 0px 2px ${color.white};
  }
`;

const ClearButton = styled(Button)`
  ${A11yOutline};
  :disabled {
    background-color: transparent;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: ${spacing.sm};

  // Make the buttons stick to the right when sign in copy disappears
  margin-left: auto;
`;
