import { durationInMinutesFromRoute } from "src/utils/adapters/duration";
import { NewTabRounded } from "src/svg/NewTab";
import styled from "styled-components";
import { spacing } from "src/theme";
import color from "src/design-system/tokens/color";
import { border_radius } from "src/design-system/tokens/border";
import { font_size, font_weight } from "src/design-system/tokens/typography";
import { FormattedDuration } from "src/components/FormattedDuration/FormattedDuration";
import { ButtonBase } from "src/components/Button/ButtonBase";
import { useGetRoutesFromCoords } from "src/utils/hooks/useGetRoutesFromCoords";
import { Skeleton } from "src/components/Skeleton/Skeleton";
import { useIntl } from "react-intl";
import { localeToLanguageCode } from "src/utils/conversions/languageCode";
import { useCallback } from "react";
import { getPath } from "src/utils/url";
import { Link } from "react-router-dom";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import type { City } from "../data/places";
import { MajorModes } from "../MajorModes";

export function Card({
  destination,
  origin,
}: {
  destination: City;
  origin: {
    canonicalName: string;
    lat: number;
    lng: number;
  };
}) {
  const imageUrl = `https://www.rome2rio.com/images/destinations/recommendations/${destination.canonicalName}_sm.jpg`;
  const intl = useIntl();
  const languageCode = localeToLanguageCode(intl.locale);
  const getExitUrl = useCallback(() => {
    return getPath(
      origin.canonicalName,
      destination.canonicalName,
      languageCode
    );
  }, [origin.canonicalName, destination.canonicalName, languageCode]);

  return (
    <CardLink
      onClick={() =>
        sendAnalyticsInteractionEvent({
          category: "Discover",
          action: "Click:Exit",
          label: getExitUrl(),
        })
      }
      to={getExitUrl()}
      target="_blank"
    >
      <Image src={imageUrl} alt={destination.shortName} />
      <Info>
        <Name>{destination.shortName}</Name>
        <TransportInfo {...{ origin, destination }} />
      </Info>
    </CardLink>
  );
}

type TransportInfoProps = {
  origin: {
    lat: number;
    lng: number;
    canonicalName: string;
  };
  destination: City;
};
function TransportInfo({ origin, destination }: TransportInfoProps) {
  const { data: searchResponse, isLoading } = useGetRoutesFromCoords(
    origin.lat,
    origin.lng,
    destination.lat,
    destination.lng
  );

  if (isLoading) {
    return (
      <TransportInfoDiv>
        <Skeleton height={18} width={124} />
        <Skeleton width={50} height={18} />
      </TransportInfoDiv>
    );
  }

  if (!searchResponse) {
    return;
  }

  const duration = durationInMinutesFromRoute(
    searchResponse,
    searchResponse.routes[0]
  );

  return (
    <TransportInfoDiv>
      <MajorModes searchResponse={searchResponse} />
      {searchResponse.routes[0].duration ? (
        <StyledFormattedDuration totalMinutes={duration} />
      ) : null}
      <NewTabButton>
        <NewTabRounded tint="cod" />
      </NewTabButton>
    </TransportInfoDiv>
  );
}

export function CardSkeleton() {
  return (
    <CardLink as="div">
      <Skeleton height={140} />
      <Info>
        <Skeleton height={16} width={150} />
        <Skeleton height={16} width={150} />
      </Info>
    </CardLink>
  );
}

const Image = styled.img`
  width: 100%;
  height: 140px;
  border-radius: ${border_radius.rounded_md};
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${spacing.lg};
  gap: ${spacing.sm};
`;

const Name = styled.span`
  font-weight: ${font_weight.medium};
  font-size: ${font_size.text_base};
  color: ${color.text.text};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TransportInfoDiv = styled.div`
  display: flex;
  gap: ${spacing.md};
`;

const StyledFormattedDuration = styled(FormattedDuration)`
  font-size: ${font_size.text_sm};
`;

const NewTabButton = styled(ButtonBase)`
  width: 16px;
  svg {
    padding: ${spacing.xs};
  }
`;

const CardLink = styled(Link)`
  display: flex;
  flex-direction: column;
  padding: ${spacing.sm};
  background-color: ${color.bg.fill.fill};
  border-radius: ${border_radius.rounded_lg};
  overflow: hidden;

  // Reset
  text-decoration: none;
  color: initial;

  &:hover {
    ${Name} {
      color: ${color.text.brand};
      text-decoration: underline;
    }
    ${NewTabButton} svg path {
      fill: ${color.text.brand};
    }
  }
`;
