import { useLogExitCallback } from "src/analytics/useLogExitCallback/useLogExitCallback";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { generateExitPoint } from "src/analytics/generateExitPoint/generateExitPoint";
import { logDataPartnerCarHireExit } from "src/data-partners/logDataPartnerExit";
import { useIntl } from "react-intl";
import type { AnalyticsEventCategory } from "src/analytics/analyticsEventTypes";
import { getCarHireDeeplinkRedirectUrl } from "../url";
import { preferredNameFromPlace } from "../adapters/place";
import { vehicleFromSegment } from "../adapters/vehicle";
import { localeToLanguageCode } from "../conversions/languageCode";
import useSearch from "./useSearch";
import { useIsTripScreen } from "./useIsTripScreen";

export default function useCarHireClick(
  analyticsCategory: AnalyticsEventCategory = "SearchResults"
) {
  const { searchResponse } = useSearch();
  const intl = useIntl();
  const isTripsScreen = useIsTripScreen();

  const logExitToCarHire = useLogExitCallback(
    "RentalCars",
    isTripsScreen ? "tripPlannerSearchResults" : "searchResults",
    "carCta",
    "car"
  );

  function handleCarHirePromoClicked() {
    if (!searchResponse) {
      return;
    }

    const destinationIndex = searchResponse.result.destinationPlace;
    const destinationPlace = searchResponse.places[destinationIndex];
    const originIndex = searchResponse.result.originPlace;
    const originPlace = searchResponse.places[originIndex];

    let canDrive = false;

    for (let route of searchResponse.routes) {
      if (route.segments.length === 1) {
        const singleSegmentVehicle = vehicleFromSegment(
          searchResponse,
          searchResponse.segments[route.segments[0]]
        );

        if (singleSegmentVehicle.kind === "car") {
          canDrive = true;
          break;
        }
      }
    }

    const carPickupPlace = canDrive ? originPlace : destinationPlace;
    const requestId = searchResponse.request.requestId;

    sendAnalyticsInteractionEvent({
      category: analyticsCategory,
      action: "Click:CarHirePromo",
    });
    logDataPartnerCarHireExit({
      origin: originPlace.canonicalName,
      destination: destinationPlace.canonicalName,
      languageCode: localeToLanguageCode(intl.locale),
      isCrimtanEnabled:
        searchResponse.analytics?.interest_data?.Crimtan?.enabled,
      isAdaraEnabled: searchResponse.analytics?.interest_data?.Adara?.enabled,
    });

    const url = getCarHireDeeplinkRedirectUrl(
      requestId,
      generateExitPoint("searchResults", "carCta", "car"),
      preferredNameFromPlace(carPickupPlace),
      carPickupPlace.lat,
      carPickupPlace.lng
    );
    logExitToCarHire(url);
    window.open(url, "_blank", "noopener noreferrer");
  }

  return handleCarHirePromoClicked;
}
