import { sendAnalyticsNonInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import {
  type SojernOptions,
  type SojernPageType,
  buildSojernUrl,
} from "./buildSojernUrl";

// Cache the options so we can simplify the API.
let optionsCache: SojernOptions | undefined;
let loadCount = 1;
let triggerCount = 1;

export const SOJERN_CONSENT_PURPOSES_REQUIRED = [1, 3, 5];
export const SOJERN_LEGITIMATE_INTEREST_PURPOSES_REQUIRED: number[] = [];
export const SOJERN_SALE_CONSENT_REQUIRED = true;
export const SOJERN_SHARING_CONSENT_REQUIRED = true;

export const sojernPixel = {
  triggerPageLoad(values: SojernOptions): void {
    const url = buildSojernUrl(values);

    setSojernPixel(url);

    sendAnalyticsNonInteractionEvent({
      category: "InterestData",
      action: "TriggerIFrame",
      label: `Sojern:${triggerCount++}`,
    });
    // Store the options so we can use them for exit tracking.
    optionsCache = values;
  },

  /**
   * @param url The URL that would be tracked by Sojern.
   * @returns True if the URL is eligible to be tracked by Sojern, false otherwise.
   */
  isExitUrlTracked(url: string): boolean {
    // Only internal pages can be tracked by Sojern. External links should
    // be ignored.
    return !!Object.values<RegExp>(pageTypeRegexes).find((regex) =>
      regex.test(url)
    );
  },

  /**
   *
   * Logs an exit event to Sojern.
   *
   * The intention here is to approximates the behavior as if we
   * had a pixel on the page that are being exited to.
   *
   * Note: If Sojern was not set up this function is a no-op. So, you do
   * not need to guard calls to it with a check if Sojern is enabled.
   *
   * @param exitUrl The URL that the user is exiting to.
   */
  trackExit(exitUrl: string) {
    if (!optionsCache) {
      // Early exit - the page load was never triggered and so Sojern was
      // not set up.
      return;
    }

    const pageType = sojernPageTypeFromExitUrl(exitUrl);
    const newOptions: SojernOptions = { ...optionsCache, pageType };
    const url = buildSojernUrl(newOptions);

    setSojernPixel(url);
    sendAnalyticsNonInteractionEvent({
      category: "InterestData",
      action: "TriggerIFrame",
      label: `Sojern:${triggerCount++}`,
    });
    optionsCache = newOptions;
  },
};

function setSojernPixel(url: string) {
  let sojernPixel = document.querySelector<HTMLIFrameElement>("#sojern-pixel");
  if (!sojernPixel) {
    // Create a new iframe because we haven't created one yet.
    sojernPixel = createSojernPixel();
    sojernPixel.src = url;
    document.body.append(sojernPixel);
  } else {
    // Reuse the existing iframe.
    sojernPixel.src = url;
  }
}

function createSojernPixel(): HTMLIFrameElement {
  const iframe = document.createElement("iframe");
  iframe.id = "sojern-pixel";
  iframe.style.display = "none";
  iframe.width = "1";
  iframe.height = "1";
  // @ts-expect-error - TypeScript types indicate this is an error, but it works
  // and is necessary for Sojern to work.
  iframe.sandbox = "allow-scripts";
  iframe.onload = () => {
    sendAnalyticsNonInteractionEvent({
      category: "InterestData",
      action: "LoadIFrame",
      label: `Sojern:${loadCount++}`,
    });
  };
  return iframe;
}

const pageTypeRegexes: {
  [key in Exclude<SojernPageType, "AirportLanding">]: RegExp;
} = {
  BusSearch: /^.*\/map\/.*#r\/Bus/i,
  TrainSearch: /^.*\/map\/.*#r\/Train/i,
  FerrySearch: /^.*\/map\/.*#r\/Ferry/i,
  FlightSearch: /^.*\/map\/.*#r\/Fly/i,
  TripSearch: /^.*\/map\//i,
  TicketSearch: /^.*\/tickets\//i,
};

function sojernPageTypeFromExitUrl(url: string): SojernPageType {
  for (const [pageType, regex] of Object.entries<RegExp>(pageTypeRegexes)) {
    if (regex.test(url)) {
      return pageType as SojernPageType;
    }
  }
  return "AirportLanding";
}
