import { useIntl } from "react-intl";
import { useScroll } from "src/ScrollContext";
import { useNavigateToHotelsPage } from "src/utils/hooks/useNavigateToHotelsPage";
import useSearch from "src/utils/hooks/useSearch";
import { useExitOnHExEntry } from "src/domain/HotelsScreen/utils-exit-to-provider";
import { useFeature } from "src/feature/useFeature";
// Using alternative copy from desktop in experiment https://rome2rio.atlassian.net/browse/DAP-2207
import { useIsTripScreen } from "src/utils/hooks/useIsTripScreen";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { Hotel } from "../../../svg/Hotel";
import { SearchResultsPromo } from "../SearchResultsPromo/SearchResultsPromo";
import messages from "./SearchResultsHotelPromo.messages";

type Props = {
  className?: string;
};

export function SearchResultsHotelPromo(props: Props) {
  const hexIsExpedia = useFeature("ExpediaOnEntry");
  const handleExpediaExit = useExitOnHExEntry("Expedia");

  const intl = useIntl();
  const { origin, destination } = useSearch();
  const { navigateToHotels } = useNavigateToHotelsPage();
  const { setScrollTop } = useScroll();
  const isTripScreen = useIsTripScreen();

  function handleHotelPromoClicked() {
    sendAnalyticsInteractionEvent({
      category: "SearchResults",
      action: "Click:HotelPromo",
    });
    setScrollTop(0);

    // If we're in the trip planner modal, we want to override the canonicals
    // when forwarding to the hotels screen.
    if (isTripScreen) {
      handleTripAccomClick();
    } else {
      if (hexIsExpedia && handleExpediaExit) {
        handleExpediaExit({
          lat: destination?.lat,
          lng: destination?.lng,
          canonicalName: destination?.canonicalName,
        });
      } else {
        navigateToHotels();
      }
    }
  }

  function handleTripAccomClick() {
    sendAnalyticsInteractionEvent({
      category: "TripPlanner",
      action: "Click:SearchResultsHotelPromo",
    });
    if (destination) {
      navigateToHotels({
        originCanonical: origin?.canonicalName,
        destinationCanonical: destination.canonicalName,
      });
    }
  }

  const hotelCta = intl.formatMessage(messages.header, {
    location: destination?.shortName ?? "",
  });

  return (
    <SearchResultsPromo
      header={hotelCta}
      iconBackgroundColor="blue"
      icon={<Hotel tint="white" />}
      onClick={handleHotelPromoClicked}
      className={props.className}
    />
  );
}
