import { type ChangeEvent, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { border_radius } from "src/design-system/tokens/border";
import color from "src/design-system/tokens/color";
import { spacing } from "src/design-system/tokens/spacing";
import { localeToLanguageCode } from "src/utils/conversions/languageCode";
import { getPath } from "src/utils/url";
import styled from "styled-components";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import {
  type FocusedElement,
  FocusContext,
  defaultFocusedElement,
} from "../../FocusContext";
import type { AutocompletePlace } from "../../api/AutocompleteResponse";
import { MAX_RESULTS } from "../../components/AutocompleteDropdown/AutocompleteDropdown";
import { ButtonBase } from "../../components/Button/ButtonBase";
import { ClickAwayListener } from "../../components/ClickAwayListener/ClickAwayListener";
import { Icon } from "../../components/Icon/Icon";
import { Switch } from "../../svg/Switch";
import { getAutocompleteHint } from "../../utils/getAutocompleteHint";
import useSearch from "../../utils/hooks/useSearch";
import { useTheme } from "../../utils/hooks/useTheme";
import { modulo } from "../../utils/modulo";
import messages from "./LargeSearchBar.messages.ts";
import { SearchAutocomplete } from "./SearchAutocomplete/SearchAutocomplete";
import { useSearchAutocomplete } from "./useSearchAutocomplete";
import { useUpdateDisplayValueOnGeocode } from "./useUpdateDisplayValueOnGeocode";

const emptyArray: AutocompletePlace[] = [];

export function LargeSearchBar() {
  const intl = useIntl();
  const navigate = useNavigate();
  const theme = useTheme();

  const { origin: searchOrigin, destination: searchDestination } = useSearch();

  const originAutocomplete = useSearchAutocomplete(
    searchOrigin?.longName ?? ""
  );
  const destinationAutocomplete = useSearchAutocomplete(
    searchDestination?.longName ?? ""
  );

  const [isOriginClosed, setIsOriginClosed] = useState(true);
  const [isDestinationClosed, setIsDestinationClosed] = useState(true);
  const [isResetEnabled, setIsResetEnabled] = useState(true);

  const originInputRef = useRef<HTMLInputElement>(null);
  const destinationInputRef = useRef<HTMLInputElement>(null);

  const [focusedElement, setFocusedElement] = useState<FocusedElement>(
    defaultFocusedElement
  );

  useUpdateDisplayValueOnGeocode({
    currentSearchLongName: searchOrigin?.longName ?? "",
    updateDisplayValue: originAutocomplete.changeDisplayValueWithoutResults,
  });

  useUpdateDisplayValueOnGeocode({
    currentSearchLongName: searchDestination?.longName ?? "",
    updateDisplayValue:
      destinationAutocomplete.changeDisplayValueWithoutResults,
  });

  function handleSwitch() {
    sendAnalyticsInteractionEvent({
      category: "SearchBox",
      action: "Click:Switch",
    });

    if (searchOrigin?.canonicalName === searchDestination?.canonicalName)
      return;

    destinationAutocomplete.changeDisplayValueWithoutResults(
      originAutocomplete.displayValue
    );

    originAutocomplete.changeDisplayValueWithoutResults(
      destinationAutocomplete.displayValue
    );

    setIsOriginClosed(true);
    setIsDestinationClosed(true);

    navigate({
      pathname: getPath(
        searchDestination?.canonicalName,
        searchOrigin?.canonicalName,
        localeToLanguageCode(intl.locale)
      ),
      search: window.location.search,
    });
  }

  function handleOriginQueryChange(e: ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value;

    originAutocomplete.changeDisplayValueWithResults(value);

    setIsOriginClosed(false);
    // Reset the focused autocomplete dropdown element if the user continues to type into the
    // search input while cycling through the autocomplete dropdown list.
    onFocusChanged({ id: "origin", index: 0 });
  }

  function handleDestinationQueryChange(e: ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value;

    destinationAutocomplete.changeDisplayValueWithResults(value);
    setIsDestinationClosed(false);
    // Reset the focused autocomplete dropdown element if the user continues to type into the
    // search input while cycling through the autocomplete dropdown list.
    onFocusChanged({ id: "destination", index: 0 });
  }

  function handleOriginAutocompleteSelect(place: AutocompletePlace) {
    sendAnalyticsInteractionEvent({
      category: "Autocomplete",
      action: "Click:AutocompleteOption",
    });
    originAutocomplete.changeDisplayValueWithoutResults(
      place.longName ?? place.shortName
    );
    setIsOriginClosed(true);
    navigate({
      pathname: getPath(
        place.canonicalName,
        searchDestination?.canonicalName,
        localeToLanguageCode(intl.locale)
      ),
      search: window.location.search,
    });
  }

  function handleDestinationAutocompleteSelect(place: AutocompletePlace) {
    sendAnalyticsInteractionEvent({
      category: "Autocomplete",
      action: "Click:AutocompleteOption",
    });
    destinationAutocomplete.changeDisplayValueWithoutResults(
      place.longName ?? place.shortName
    );
    setIsDestinationClosed(true);
    navigate({
      pathname: getPath(
        searchOrigin?.canonicalName,
        place.canonicalName,
        localeToLanguageCode(intl.locale)
      ),
      search: window.location.search,
    });
  }

  // We need to do capture this so we know to stop trying to show a hint each time
  // a character is removed until the user starts typing again.
  const [justPressedBackspace, setJustPressedBackspace] = useState(false);

  const originResults = isOriginClosed
    ? emptyArray
    : originAutocomplete.results;
  // We want the input value to match the currently focused autocomplete dropdown element.
  // First we check the focused element kind and make sure the index is more than zero,
  // index 1 being the first autocomplete dropdown element.
  const originDropdownFocused =
    focusedElement.id === "origin" && focusedElement.index > 0;
  const originDisplayValue = originDropdownFocused
    ? originResults[focusedElement.index - 1]?.longName
    : originAutocomplete.displayValue;

  const originLongName = originResults[0]?.longName;

  const originHint =
    justPressedBackspace || originDropdownFocused
      ? ""
      : getAutocompleteHint(originAutocomplete.displayValue, originLongName);

  const destinationResults = isDestinationClosed
    ? emptyArray
    : destinationAutocomplete.results;
  // We want the input value to match the currently focused autocomplete dropdown element.
  // First we check the focused element id and make sure the index is more than zero,
  // index 1 being the first autocomplete dropdown element.
  const destinationDropdownFocused =
    focusedElement.id === "destination" && focusedElement.index > 0;
  const destinationDisplayValue = destinationDropdownFocused
    ? destinationResults[focusedElement.index - 1]?.longName
    : destinationAutocomplete.displayValue;

  const destinationLongName = destinationResults[0]?.longName;
  const destinationHint =
    justPressedBackspace || destinationDropdownFocused
      ? ""
      : getAutocompleteHint(
          destinationAutocomplete.displayValue,
          destinationLongName
        );

  // This means the input is focused and not on one of the drop down results
  const isInputFocused = focusedElement.index === 0;

  function handleEnterOrigin(event: React.KeyboardEvent<HTMLInputElement>) {
    event.preventDefault();
    setIsResetEnabled(false);
    const focusedOrigin =
      originResults[isInputFocused ? 0 : focusedElement.index - 1];

    handleOriginAutocompleteSelect(focusedOrigin);
    setJustPressedBackspace(false);
    destinationInputRef.current?.focus();
  }

  function handleEnterDestination(
    event: React.KeyboardEvent<HTMLInputElement>
  ) {
    event.preventDefault();
    setIsResetEnabled(false);
    const focusedDestination =
      destinationResults[isInputFocused ? 0 : focusedElement.index - 1];
    handleDestinationAutocompleteSelect(focusedDestination);
    setJustPressedBackspace(false);
    destinationInputRef.current?.blur();
  }

  function onKeyDown(
    event: React.KeyboardEvent<HTMLInputElement>,
    type: "origin" | "destination"
  ) {
    switch (event.key) {
      case "Backspace":
      case "Delete":
      case "ArrowLeft":
        setJustPressedBackspace(true);
        break;
      case "Enter":
        type === "origin"
          ? handleEnterOrigin(event)
          : handleEnterDestination(event);
        break;
      case "Tab":
        if (isInputFocused) break;
        type === "origin"
          ? handleEnterOrigin(event)
          : handleEnterDestination(event);
        break;
      case "ArrowRight": {
        if (!justPressedBackspace) {
          type === "origin"
            ? !isOriginClosed && handleEnterOrigin(event)
            : !isDestinationClosed && handleEnterDestination(event);
        }
        break;
      }
      default:
        setJustPressedBackspace(false);
    }
  }

  function onFocusChanged(newFocusedElement: FocusedElement) {
    let index = newFocusedElement.index;

    switch (newFocusedElement.id) {
      case "origin":
        index = modulo(index, originResults.slice(0, MAX_RESULTS).length + 1);
        break;
      case "destination":
        index = modulo(
          index,
          destinationResults.slice(0, MAX_RESULTS).length + 1
        );
        break;
    }
    index = Number.isNaN(index) ? 0 : index;
    setFocusedElement({ id: newFocusedElement.id, index });
  }

  return (
    <FocusContext.Provider value={{ focusedElement, onFocusChanged }}>
      <Container>
        <InputClickAwayListener onClickAway={() => setIsOriginClosed(true)}>
          <SearchAutocomplete
            kind="origin"
            value={originDisplayValue}
            onChange={handleOriginQueryChange}
            results={originResults}
            onAutocompleteSelect={handleOriginAutocompleteSelect}
            hint={originHint}
            ref={originInputRef}
            onKeyDown={(event) => onKeyDown(event, "origin")}
            onFocus={() => setIsResetEnabled(true)}
            reset={() => {
              if (isResetEnabled) {
                originAutocomplete.changeDisplayValueWithoutResults(
                  searchOrigin?.longName ?? ""
                );
              }
            }}
          />
        </InputClickAwayListener>

        <SwitchButton
          onClick={handleSwitch}
          aria-label={intl.formatMessage(messages.switchLabel)}
        >
          <Icon size="lg">
            <Switch
              title="switch"
              tint={theme.searchBar.switchButton.iconTint}
            />
          </Icon>
        </SwitchButton>

        <InputClickAwayListener
          onClickAway={() => setIsDestinationClosed(true)}
        >
          <SearchAutocomplete
            kind="destination"
            value={destinationDisplayValue}
            onChange={handleDestinationQueryChange}
            results={destinationResults}
            onAutocompleteSelect={handleDestinationAutocompleteSelect}
            hint={destinationHint}
            ref={destinationInputRef}
            onKeyDown={(event) => onKeyDown(event, "destination")}
            onFocus={() => setIsResetEnabled(true)}
            reset={() => {
              if (isResetEnabled) {
                destinationAutocomplete.changeDisplayValueWithoutResults(
                  searchDestination?.longName ?? ""
                );
              }
            }}
          />
        </InputClickAwayListener>
      </Container>
    </FocusContext.Provider>
  );
}

const InputClickAwayListener = styled(ClickAwayListener)`
  flex: 1 1;
  max-width: 240px;
`;

const SwitchButton = styled(ButtonBase)`
  border-radius: ${border_radius.rounded_md};
  width: 48px;
  min-width: 48px;
  // We want the inner icon to be 18px in size, so we apply 15px padding because
  // 48px - 30px = 18px.
  padding: 15px;
  height: 48px;
  background-color: ${color.bg.surface.active};
  border: 1px solid ${color.border.secondary};

  &:hover {
    background-color: ${color.input.bgSurfaceActive};

    // Reset the background-color on touch devices so that they don't get a
    // lingering hover effect after a click event.
    @media (hover: none) {
      background-color: ${color.input.bgSurface};
    }
  }

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: ${spacing.xs} ${spacing.xxl} ${spacing.md};
  & > * {
    margin-right: ${spacing.md};
  }
`;
