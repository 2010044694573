import { type MutableRefObject, useEffect, useState } from "react";
import { sendAnalyticsNonInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import type { AnalyticsEventCategory } from "src/analytics/analyticsEventTypes";
import { useIsInViewport } from "./useIsInViewport";

type Args = {
  ref: MutableRefObject<HTMLElement | null>;
  category: AnalyticsEventCategory;
  action: string;
  label?: string;
  baseOffsetForScrollIn?: number;
  threshold?: number;
};
export function useAnalyticsEventHasEnteredViewport({
  ref,
  category,
  action,
  label,
  baseOffsetForScrollIn = 0,
  threshold = 0.15,
}: Args) {
  const elementInViewport = useIsInViewport(
    ref,
    threshold,
    baseOffsetForScrollIn
  );
  const [hasElementBeenViewed, setHasElementBeenViewed] = useState(false);

  useEffect(() => {
    if (ref.current && !hasElementBeenViewed && elementInViewport) {
      sendAnalyticsNonInteractionEvent({ category, action, label });
      setHasElementBeenViewed(true);
    }
  }, [ref, action, category, label, elementInViewport, hasElementBeenViewed]);
}
