import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { Actions } from "src/components/TripPlanner/Actions/Actions";
import { TitleTripPlanner } from "src/components/TripPlanner/Headings/Headings";
import styled from "styled-components";
import { spacing } from "src/theme";
import { useTripPlannerContext } from "../hooks/useTripPlannerContext";

export default function SmallHeading({ titleText }: { titleText: string }) {
  const { history, dispatch, tripPlannerDetails } = useTripPlannerContext();
  const hideDefaultTripHeader = !tripPlannerDetails.id;

  function undo() {
    dispatch({ type: "UNDO" });
    sendAnalyticsInteractionEvent({
      category: "TripPlanner",
      action: "Click:Undo",
    });
  }

  function redo() {
    dispatch({ type: "REDO" });
    sendAnalyticsInteractionEvent({
      category: "TripPlanner",
      action: "Click:Redo",
    });
  }

  return (
    <Container>
      <TitleTripPlanner data-testid="trip-planner-title">
        {titleText}
      </TitleTripPlanner>
      {history && !hideDefaultTripHeader && (
        <Actions
          undoCallback={undo}
          redoCallback={redo}
          canUndo={history.canUndo}
          canRedo={history.canRedo}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: ${spacing.md};
`;
