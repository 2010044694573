import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import type { ErrorEvent, EventHint } from "@sentry/react";
import { useEffect } from "react";

export function initSentry() {
  Sentry.init({
    dsn: import.meta.env.PROD
      ? "https://5a4850844529d6b7d34f637f591b879a@o4505628143255552.ingest.sentry.io/4505632747487232"
      : undefined,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.globalHandlersIntegration({
        onerror: false,
        onunhandledrejection: false,
      }),
    ],

    // Sentry uses the commit sha for releases.
    // This is a bit safer than using the VITE_VERSION, which is a number that could conflict with other projects
    // As sentry shares release version names across all projects.
    release: import.meta.env.VITE_RELEASE_VERSION,
    environment: import.meta.env.VITE_ENVIRONMENT,
    autoSessionTracking: true,

    // Performance monitoring
    tracesSampleRate: 0.02, // 2% of requests/transactions will be monitored not to exceed our quota
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/rome2rio\.com\//,
      /^https:\/\/mweb\.rome2rio\.com\//,
    ],

    // Session Replays.
    replaysSessionSampleRate: 0, // 0% of sessions without errors will be captured
    replaysOnErrorSampleRate: 0.05, // 5% of sessions with errors will be captured

    denyUrls: [
      // Updating Sentry to 7.67.0 has started throwing internal errors due to Hotjar.
      // https://github.com/getsentry/sentry-javascript/pull/8890
      /https:\/\/script.hotjar.com\/*/,
      // Media.net header bidding libraries have issues with some headless browsers adding too much noise.
      /https:\/\/warp.media.net\/*/,
      /https:\/\/cdn.flashtalking.com\/*/,
      /https:\/\/s0.2mdn.net\/*/,
      /https:\/\/cdn.ampproject.org\/*/,
      /https:\/\/fundingchoicesmessages.google.com\/*/,
      // Crimtan script throwing errors on iphone
      /https:\/\/consentag.eu\/*/,
    ],

    ignoreErrors: [
      // Ignore iframes throwing errors https://github.com/getsentry/sentry/issues/5267
      /Blocked a frame with origin/,
      // Ignore non-errors https://github.com/getsentry/sentry-javascript/issues/2292
      "Non-Error exception captured",
      // Google Maps
      /Failed to load map api/, // We always handle this error properly, and it's almost always due to network issues.
      `Cannot read properties of null (reading 'zoom')`,
      /undefined is not an object \(evaluating '.+\.lat'\)/,
      "i is undefined",
      // Bunch of errors appearing to orginate in 3rd party ad tech
      // e.g. https://rome2rio.sentry.io/issues/5035769826/?project=4505632747487232&query=is%3Aunresolved&referrer=issue-stream&sort=freq&statsPeriod=24h&stream_index=0&utc=true
      'Blocked a restricted frame with origin "https://www.rome2rio.com" from accessing another frame',
      /Failed to execute 'postMessage' on 'Window'/,
      // Ignore GA's gtag internal errors
      /undefined is not an object \(evaluating 'b\.container\[a\]'\)/,
      /getRestrictions is not a function/,
    ],

    beforeSend(
      event: ErrorEvent,
      hint: EventHint
    ): PromiseLike<ErrorEvent | null> | ErrorEvent | null {
      // Discard events we suspect to come from crawlers.
      // Odd that sentry doesn't check this, as we have 'Filter out known web crawlers' enabled in the project settings.
      // Note: We could also filter by browser = 'HeadlessChrome x.x.x', but this may include errors google's bot crawler would get.
      if (
        event.request?.headers?.["User-Agent"]?.includes("PulsePoint-Crawler")
      ) {
        return null;
      }

      return event;
    },
  });

  loadReplay();
}

async function loadReplay() {
  const { replayIntegration } = await import("@sentry/react");

  Sentry.addIntegration(replayIntegration());
}
