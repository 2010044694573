import {
  type AmenityType,
  type FacilityType,
  type HotelPropertyKind,
  HotelType,
} from "../../api/HotelListResponse";
import { primitiveArrayEquals } from "../../utils/primitiveArrayEquals";
import type { Checkable } from "./HotelFilter/FilterComponents/CheckboxList";

export type HotelsFilterState = {
  priceLowerBound: number | undefined;
  priceUpperBound: number | undefined;
  selectPriceLower: number | undefined;
  selectPriceUpper: number | undefined;
  popular: Checkable;
  paymentOptions: Checkable;
  guestRating: Checkable;
  starRating: StarRatingType;
  amenities: AmenitiesByCode;
  propertyTypes: PropertyTypesByCode;
  propertyTypeDescriptionArray: Array<HotelPropertyKind>;
  userCurrency: String;
};

export type AmenitiesByCode = {
  [key in FacilityType]?: { name: string; checked: boolean };
};

export type PropertyTypesByCode = {
  [key in HotelType]?: { name: string; checked: boolean };
};

export type HotelsFilterAction =
  | { type: "CLEAR_ALL" }
  | { type: "CLEAR_PRICE" }
  | { type: "UPDATE_FILTERS"; payload: HotelsFilterState }
  | { type: "UPDATE_POPULAR"; popular: Checkable }
  | { type: "UPDATE_PAYMENT_OPTIONS"; paymentOptions: Checkable }
  | { type: "UPDATE_GUEST_RATING"; guestRating: Checkable }
  | { type: "UPDATE_STAR_RATING"; starRating: StarRatingType }
  | { type: "UPDATE_AMENITIES"; amenities: AmenitiesByCode }
  | { type: "UPDATE_PROPERTY_TYPE"; propertyTypes: PropertyTypesByCode }
  | { type: "UPDATE_PRICE_SELECTION"; priceSelection: PriceSelection }
  | { type: "POPULATE_AMENITIES"; hotelFacilities: AmenityType[] }
  | { type: "POPULATE_PROPERTY_TYPES"; propertyTypes: HotelPropertyKind[] }
  | { type: "POPULATE_PRICE_RANGE"; priceRange: PriceRange; currency: String }
  | { type: "POPULATE_GUEST_RATING"; maxReviewScore: number };

export type StarRatingType = {
  "1": boolean;
  "2": boolean;
  "3": boolean;
  "4": boolean;
  "5": boolean;
};

export type AmenityIdDescriptionType = {
  [key: string]: string;
};

// Some options are universal, and can be hard-coded.
export const defaultFilterState: HotelsFilterState = {
  priceLowerBound: undefined,
  priceUpperBound: undefined,
  selectPriceLower: undefined,
  selectPriceUpper: undefined,
  popular: {},
  paymentOptions: {},
  guestRating: {},
  starRating: {
    "1": false,
    "2": false,
    "3": false,
    "4": false,
    "5": false,
  },
  amenities: {},
  propertyTypes: {
    [HotelType.Hotel]: { name: "Hotels", checked: true },
  },
  propertyTypeDescriptionArray: [],
  userCurrency: "",
};

export const filtersReducer = (
  state: HotelsFilterState,
  action: HotelsFilterAction
) => {
  switch (action.type) {
    case "CLEAR_ALL":
      return {
        ...state,
        selectPriceLower: state.priceLowerBound,
        selectPriceUpper: state.priceUpperBound,
        paymentOptions: falsifyFlagObject(state.paymentOptions),
        guestRating: falsifyFlagObject(state.guestRating),
        starRating: defaultFilterState.starRating,
        amenities: falsifyByCodeObject(state.amenities),
        propertyTypes: falsifyByCodeObject(state.propertyTypes),
      };
    case "CLEAR_PRICE":
      return {
        ...state,
        selectPriceLower: state.priceLowerBound,
        selectPriceUpper: state.priceUpperBound,
      };
    case "UPDATE_FILTERS":
      return {
        ...state,
        ...action.payload,
      };

    case "UPDATE_POPULAR":
      return {
        ...state,
        popular: action.popular,
      };
    case "UPDATE_PAYMENT_OPTIONS":
      return {
        ...state,
        paymentOptions: action.paymentOptions,
      };
    case "UPDATE_GUEST_RATING":
      return {
        ...state,
        guestRating: action.guestRating,
      };
    case "UPDATE_STAR_RATING":
      return {
        ...state,
        starRating: action.starRating,
      };
    case "UPDATE_AMENITIES":
      return {
        ...state,
        amenities: action.amenities,
      };
    case "UPDATE_PROPERTY_TYPE":
      return {
        ...state,
        propertyTypes: action.propertyTypes,
      };

    case "UPDATE_PRICE_SELECTION":
      return {
        ...state,
        selectPriceLower: action.priceSelection.lower,
        selectPriceUpper: action.priceSelection.upper,
      };

    case "POPULATE_AMENITIES":
      const amenitiesById: AmenitiesByCode = action.hotelFacilities.reduce(
        (obj, amenity) => {
          return {
            ...obj,
            [amenity.facilityId]: {
              name: amenity.facilityName,
              checked: false,
            },
          };
        },
        {}
      );

      const currentAmenityNames = Object.values(state.amenities)
        .map((amenity) => amenity.name)
        .sort();
      const providerAmenityNames = action.hotelFacilities
        .map((amenity) => amenity.facilityName)
        .sort();

      const areSeededAmenityNamesTheSame = primitiveArrayEquals(
        currentAmenityNames,
        providerAmenityNames
      );

      // If no currentAmenityNames, we are at first app load. If the seeded amenityNames have changed, the user changed language setting.
      if (currentAmenityNames.length === 0 || !areSeededAmenityNamesTheSame) {
        return {
          ...state,
          amenities: amenitiesById,
        };
      }
      return state;

    case "POPULATE_PROPERTY_TYPES":
      // If the state contains any property types at this point, they are the defaults from the initial query.
      const defaultPropertyTypeFilters = Object.keys(state.propertyTypes);
      const propertyTypesById: AmenitiesByCode = action.propertyTypes.reduce(
        (obj, propertyType) => {
          // If the property type is in the default filters, keep it checked.
          const isChecked = defaultPropertyTypeFilters.includes(
            propertyType.hotelTypeId.toString()
          );
          return {
            ...obj,
            [propertyType.hotelTypeId]: {
              name: propertyType.hoteTypeName,
              checked: isChecked,
            },
          };
        },
        {}
      );

      const currentPropertyTypeNames = Object.values(state.propertyTypes)
        .map((pt) => pt.name)
        .sort();
      const providerPropertyTypeNames = action.propertyTypes
        .map((pt) => pt.hoteTypeName)
        .sort();

      const areSeededPropertyTypeNamesTheSame = primitiveArrayEquals(
        currentPropertyTypeNames,
        providerPropertyTypeNames
      );

      // If no currentPropertyTypeNames we are at first app load. If the seeded propertyTypeNames have changed, the user changed language setting.
      if (
        currentPropertyTypeNames.length === 0 ||
        !areSeededPropertyTypeNamesTheSame
      ) {
        return {
          ...state,
          propertyTypes: propertyTypesById,
        };
      }
      return state;

    case "POPULATE_PRICE_RANGE":
      const minPrice = action.priceRange.min;
      const maxPrice = action.priceRange.max;
      const currency = action.currency;
      let selectPriceUpper;
      let selectPriceLower;

      // Preserve existing selection unless out of price range. Reset on currency change
      if (currency !== state.userCurrency) {
        selectPriceUpper = maxPrice;
        selectPriceLower = minPrice;
      } else {
        selectPriceUpper = Math.min(
          state?.selectPriceUpper ?? Number.POSITIVE_INFINITY,
          maxPrice
        );
        selectPriceLower = Math.max(state?.selectPriceLower ?? 0, minPrice);
      }

      return {
        ...state,
        priceLowerBound: minPrice,
        priceUpperBound: maxPrice,
        selectPriceLower: selectPriceLower,
        selectPriceUpper: selectPriceUpper,
        userCurrency: currency,
      };

    case "POPULATE_GUEST_RATING":
      const maxReviewScore = action.maxReviewScore;
      let guestRating;
      // Preserve existing selections
      if (
        Object.keys(state.guestRating).length > 0 &&
        state.guestRating.constructor === Object
      ) {
        guestRating = state.guestRating;
      } else if (maxReviewScore === 5) {
        guestRating = {
          "0": false,
          "1": false,
          "2": false,
          "3": false,
          "4": false,
        };
      } else if (maxReviewScore === 10) {
        guestRating = {
          "0": false,
          "6": false,
          "7": false,
          "8": false,
          "9": false,
        };
      } else {
        guestRating = {};
      }
      return {
        ...state,
        guestRating: guestRating,
      };

    default:
      throw new Error(`Unknown action type`);
  }
};

export const countActivatedFilters = (state: HotelsFilterState) => {
  let count = 0;
  Object.keys(state).forEach((filter) => {
    if (filter === "amenities") {
      count += Object.values(state.amenities).filter(
        (amenity) => amenity.checked
      ).length;
    }
    if (filter === "propertyTypes") {
      count += Object.values(state.propertyTypes).filter(
        (pt) => pt.checked
      ).length;
    }
    if (
      filter === "provider" ||
      filter === "maxReviewScore" ||
      filter === "propertyTypes" ||
      filter === "priceLow" ||
      filter === "priceHigh" ||
      filter === "selectPriceLower" ||
      filter === "selectPriceUpper" ||
      filter === "userCurrency" ||
      filter === "amenities"
    ) {
      // Done counting for this key in filterState.
      return;
    }

    const filterObject = state[filter as keyof HotelsFilterState];
    if (filterObject === undefined) return;
    count += Object.values(filterObject).reduce((acc, curr) => acc + curr, 0);
  });

  return count;
};

const falsifyFlagObject = (object: { [key: string]: boolean }) => {
  const shallowCopy = { ...object };
  Object.keys(shallowCopy).forEach((option) => (shallowCopy[option] = false));
  return shallowCopy;
};

export function falsifyByCodeObject<Key extends keyof object>(
  object: Record<Key, { name: string }>
) {
  const falsifiedObject: Record<Key, { name: string; checked: boolean }> =
    {} as any;

  for (const key in object) {
    falsifiedObject[key] = {
      name: object[key].name,
      checked: false,
    };
  }

  return falsifiedObject;
}

type PriceRange = {
  min: number;
  max: number;
};
type PriceSelection = {
  lower: number;
  upper: number;
};
