import { useState } from "react";
import { useIntl } from "react-intl";
import Tooltip from "src/components/Tooltip/Tooltip";
import { useFeature } from "src/feature/useFeature";
import { desktopLayout } from "src/utils/hooks/useLayout";
import styled from "styled-components";
import { ButtonBase } from "src/components/Button/ButtonBase";
import { A11yOutline } from "src/utils/accessibility";
import { spacing } from "src/design-system/tokens/spacing";
import { font_size } from "src/design-system/tokens/typography";
import { useNavigate } from "react-router";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import useUser from "src/utils/hooks/useUser";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { navigateToNewStateHash } from "src/utils/location/navigateToNewStateHash";
import { Bookmark } from "src/svg/tripplanner/Bookmark";
import { Icon as OldIcon } from "src/components/Icon/Icon";
import { Button } from "src/design-system/components/Button/Button";
import { ChevronRight } from "src/svg/designSystem/ChevronRight";
import { Icon } from "src/design-system/components/Icon/Icon";
import { SaveWrapper } from "../SingleTrip/SingleTripScreen";
import messages from "../TripPlannerScreen.messages";
import { UserAccountTakeover } from "../UserAccountTakeover/UserAccountTakeover";

export function TripSaveButton() {
  const isReturnToCore = useFeature("ReturnToCore");
  const intl = useIntl();
  const [showSigninTakeover, setShowSigninTakeover] = useState(false);

  const navigate = useNavigate();
  const location = useTypedLocation();
  const { user } = useUser();

  const isLoggedOut = !user?.id;

  function handleBookmarkClick() {
    setShowSigninTakeover(true);
    sendAnalyticsInteractionEvent({
      category: "TripPlanner",
      action: "Click:SaveTrip:MultipleTrips",
    });
  }

  function handleSavedTrips() {
    navigateToNewStateHash(
      navigate,
      {
        highlightedTab: "trips",
      },
      `#trips/saved/`,
      {
        ...location,
      }
    );
  }

  return (
    <>
      {!isReturnToCore ? (
        <>
          {isLoggedOut && (
            <Tooltip
              id="save-button-heart-tooltip"
              anchor="center"
              placement="bottom"
              placementOffset={5}
              tooltipContent={
                <TooltipText>{intl.formatMessage(messages.save)}</TooltipText>
              }
            >
              <SaveButton
                data-testid="save-trip-signin-button"
                title={intl.formatMessage(messages.save)}
                onClick={handleBookmarkClick}
              >
                <OldIcon size="md">
                  <Bookmark tint="iconForeground" />
                </OldIcon>
                <UserAccountTakeover
                  isDropdownOpen={showSigninTakeover}
                  handleClose={() => setShowSigninTakeover(false)}
                  handleClickOffDropdown={() => setShowSigninTakeover(false)}
                />
              </SaveButton>
            </Tooltip>
          )}
        </>
      ) : (
        <>
          {isReturnToCore && !isLoggedOut ? (
            <SaveWrapper>
              <Button onPress={handleSavedTrips} size="small" variant="subtle">
                {intl.formatMessage(messages.savedTrips)}
                <Icon size="small">
                  {" "}
                  <ChevronRight tint="cod" />
                </Icon>
              </Button>
            </SaveWrapper>
          ) : (
            <Button
              variant="primary"
              data-testid="save-trip-signin-button"
              onPress={handleBookmarkClick}
            >
              <OldIcon size="md">
                <Bookmark tint="white" />
              </OldIcon>
              {intl.formatMessage(messages.save)}
              <UserAccountTakeover
                isDropdownOpen={showSigninTakeover}
                handleClose={() => setShowSigninTakeover(false)}
                handleClickOffDropdown={() => setShowSigninTakeover(false)}
              />
            </Button>
          )}
        </>
      )}
    </>
  );
}

const TooltipText = styled.span`
  display: block;
  font-size: ${font_size.text_sm};
  padding: 0;

  ${desktopLayout} {
    padding: ${spacing.sm} ${spacing.md};
  }
`;

const SaveButton = styled(ButtonBase)`
  ${A11yOutline};

  &:hover {
    svg {
      path {
        stroke: ${(props) => props.theme.tripPlanActions.iconHover};
      }
    }
  }

  display: none;

  ${desktopLayout} {
    display: block;
  }
`;
